import React, { ReactNode, useContext } from 'react'
import BlockContent from '@sanity/block-content-to-react'

import { projectId, dataset } from '../../api/sanity'
import CodeFragment from '../CodeFragment'
import ClaimConclusion from '../ClaimConclusion'
import ArticleTeaser from '../ArticleTeaser'
import Image from '../Image'
import YouTube from '../YouTube'
import People from '../People'
import Table from '../Table'
import BarChart from '../BarChart'
import BarChartStacked from '../BarChartStacked'
import { List, ListItem } from '../List'

import { Blocks } from '../../types/blocks'
import Link from '../Link'
import { ReferencesOpenContext } from '../Article'
import IfcnBadge from '../IfcnBadge'

const SourceLink = ({ children, _key }) => {
  const { toggle: toggleReferencesOpen } = useContext(ReferencesOpenContext)

  return (
    <Link onClick={() => toggleReferencesOpen(true)} href={`#ref-${_key}`}>
      {children}
    </Link>
  )
}

export const DEFAULT_SERIALIZERS = {
  list({ type, ...props }) {
    return <List type={type === 'bullet' ? 'ul' : 'ol'} {...props} />
  },
  listItem(props: any) {
    return <ListItem {...props} />
  },
  types: {
    // if you add a new type, make sure it's handled by the NTB converter and atom feed as well
    html: ({ node }: any) => <CodeFragment {...node} html={node.code} />,
    oembed: ({ node }: any) => <CodeFragment html={node.html} />,
    faktiskEmbed: ({ node }: any) => <ArticleTeaser {...node} />,
    relatedArticles: ({ node }: any) => <ArticleTeaser {...node} />,
    claimConclusion: ({ node }: any) => <ClaimConclusion {...node} />,
    // eslint-disable-next-line jsx-a11y/alt-text
    image: ({ node }: any) => <Image {...node} />,
    youtube: ({ node }: any) => <YouTube {...node} />,
    people: ({ node }: any) => <People {...node} />,
    ifcn: () => <IfcnBadge />,
    table: ({ node }: any) => {
      if (node.style === 'bars' || node.style === 'bars-horizontal') {
        return <BarChart {...node} />
      } else if (node.style === 'bars-stacked') {
        return <BarChartStacked {...node} />
      }

      return <Table {...node} />
    },
  },
  marks: {
    source: SourceLink,
    internalLink: ({ children, mark }) => {
      const type = mark?.reference?._type
      const slug = mark?.reference?.slug

      const urlBase = {
        article: '/artikler/',
        aboutUs: '/',
        funding: '/finansiering',
      }[type]

      if (!slug || !urlBase) {
        return children
      }

      let href

      if (type === 'funding') {
        href = urlBase
      } else {
        href = `${urlBase}${slug.current}${slug.pretty ? `/${slug.pretty}` : ''}`
      }

      return <Link href={href}>{children}</Link>
    },
    anchor: ({ children, mark: { id } }) => {
      return <span id={id}>{children}</span>
    },
    link: ({
      children,
      mark: { href, nofollow },
    }: {
      children: ReactNode
      mark: { href: string; nofollow: boolean }
    }) => {
      const additionalProps = nofollow ? { rel: 'nofollow noopener' } : {}

      return (
        <a href={href} {...additionalProps}>
          {children}
        </a>
      )
    },
  },
}

interface Props {
  blocks?: Blocks
  className?: string
  serializers?: {
    [key: string]: any
  }
}

const RichText: React.FC<Props> = ({ blocks, className, serializers }) => {
  return (
    <BlockContent
      renderContainerOnSingleChild
      className={className}
      blocks={blocks}
      serializers={serializers || DEFAULT_SERIALIZERS}
      projectId={projectId}
      dataset={dataset}
    />
  )
}

export default RichText
