import React, { useContext, useState } from 'react'
import BlockContent from '@sanity/block-content-to-react'
import { filter, memoize } from 'lodash'

import urlHelper from '../../helpers/urls'
import BEMHelper from '../../helpers/bem'
import { formatDate } from '../../helpers/date'
import breakLongWord from '../../helpers/breakLongWord'
import portableToPlain, { getTextFromBlock, clearEmptyBlocks } from '../../helpers/portableToPlain'
import styles from './Styles.module.scss'
import RichText, { DEFAULT_SERIALIZERS } from '../RichText'
import Container from '../Container'
import Button from '../Button'
import ClaimConclusion from '../ClaimConclusion'
import Image from '../Image'
import Link from '../Link'
import Accordion from '../Accordion'
import ArticleEntries, { Article as Related } from '../ArticleEntries'
import Tags, { TagType } from '../Tags'
import Sources from '../Sources'
import YouTube, { Props as YouTubeProps } from '../YouTube'

import { Blocks, Block, MarkDef } from '../../types/blocks'
import ImageFields from '../../types/image'
import useIsEmbedded from '../../hooks/useIsEmbedded'
import Disclaimer from '../Disclaimer'

const bem = BEMHelper(styles)

interface Corrections {
  _key: string
  timestamp: string
  body: Blocks
}
interface Slug {
  current: string
  pretty: string
}

export interface Props {
  slug?: Slug
  title: string
  genre?: string
  authors?: Array<{
    name: string
    email: string
    _id: string
  }>
  _updatedAt?: string
  publishedAt?: string
  preamble?: Blocks
  body?: Blocks
  hideTitle?: boolean
  hidePreamble?: boolean
  hideTldr?: boolean
  hideImage?: boolean
  hideMargin?: boolean
  mainImage?: ImageFields
  youtube?: YouTubeProps
  tags?: TagType[]
  corrections?: Corrections[]
  page?: boolean
  related?: Related[]
  smallEmbed?: boolean
  theme?: string // dark / light
  children?: React.ReactNode
}

export const ReferencesOpenContext = React.createContext({
  open: false,
  focus: '',
  toggle: (_override: boolean): void => {},
})

const outdent = (text: string) => text.startsWith('– ')

const skipBigLetter = (text: string) => {
  if (text.length < 100) {
    // Skip short paragraphs
    return true
  } else if (text.match(/^\d/)) {
    // Skip when it starts with numbers
    return true
  } else if (
    // text.startsWith('«') ||
    text.startsWith('-') ||
    text.startsWith('–') ||
    text.startsWith('—')
  ) {
    // Skip when it's a quote
    return true
  }

  return false
}

const CreateBlockRenderer = (opts: any = {}) =>
  function BlockRenderer(props: Block) {
    const { style = 'normal' } = props.node

    if (style === 'blockquote') {
      return <blockquote {...bem('quote', { outdent: opts.outdent })}>{props.children}</blockquote>
    }

    if (style === 'normal') {
      const text = getTextFromBlock(props.children) || ''

      return (
        <p
          {...bem('text', {
            outdent: opts.outdent && outdent(text),
            'skip-big-letter': skipBigLetter(text),
          })}
        >
          {props.children}
        </p>
      )
    }

    if (style === 'h2' || style === 'h3') {
      const Node = style
      const text = getTextFromBlock(props.children) || ''
      return (
        <Node {...bem('sub-title', { outdent: opts.outdent && outdent(text) })}>
          {props.children}
        </Node>
      )
    }

    return BlockContent.defaultSerializers.types.block(props)
  }

const createSerializers = memoize(
  (opts: any = {}) => ({
    ...DEFAULT_SERIALIZERS,
    marks: {
      ...DEFAULT_SERIALIZERS.marks,
      // eslint-disable-next-line react/display-name
      smallCaps: ({ children, mark: { id } }) => {
        return (
          <strong id={id} {...bem('small-caps')}>
            {children}
          </strong>
        )
      },
    },
    listItem: (props: any) => DEFAULT_SERIALIZERS.listItem({ ...props, outdent: opts.outdent }),
    types: {
      ...DEFAULT_SERIALIZERS.types,
      block: CreateBlockRenderer({ outdent: opts.outdent }),
    },
  }),
  JSON.stringify,
)

const Article: React.FC<Props> = ({
  slug,
  title,
  preamble,
  body = [],
  hideTitle = false,
  hidePreamble = false,
  hideImage = false,
  hideMargin = false,
  genre = 'Artikkel',
  authors,
  publishedAt,
  _updatedAt,
  mainImage,
  tags,
  corrections,
  page,
  children,
  related,
  youtube,
  smallEmbed,
  theme = undefined,
}) => {
  const isEmbed = useIsEmbedded()
  const [referencesOpen, setReferencesOpen] = useState<boolean>(
    typeof window !== 'undefined' ? !!window.location.hash.match(/^#ref-/) : false,
  )

  const cleanedBody = clearEmptyBlocks(body)
  const hasTopClaim = cleanedBody && cleanedBody[0]?._type === 'claimConclusion'
  const content = hasTopClaim ? [...cleanedBody].filter((_, index) => index !== 0) : cleanedBody

  const showMainImage = mainImage && !hideImage && !youtube
  const containerSize = hideMargin ? 'fluid' : 'tight'
  const serializers = createSerializers({ outdent: !hideMargin })

  // If article has been updated more than 10 seconds after it was published,
  // consider the article to have been updated
  const hasBeenUpdated =
    !!_updatedAt &&
    !!publishedAt &&
    new Date(_updatedAt).getTime() - new Date(publishedAt).getTime() > 10000

  return (
    <ReferencesOpenContext.Provider
      value={{
        open: referencesOpen,
        focus: '', // TODO: ID for selected ref
        toggle: (override: boolean) =>
          setReferencesOpen((v) => (override !== undefined ? override : !v)),
      }}
    >
      <article {...bem('', { embed: isEmbed, [`theme-${theme}`]: theme })}>
        {!hideTitle && <ArticleHeader slug={slug} title={title} genre={genre} page={page} />}

        {preamble && !hidePreamble && (
          <Container size={containerSize}>
            <RichText blocks={preamble} {...bem('preamble')} />
          </Container>
        )}

        {showMainImage && (
          // eslint-disable-next-line jsx-a11y/alt-text
          <Image {...mainImage} style={'full'} altText={mainImage.altText ?? 'hovedbilde'} />
        )}
        {youtube && <YouTube {...youtube} main />}

        {hasTopClaim && <ClaimConclusion {...cleanedBody[0]} />}

        {smallEmbed && slug && (
          <div {...bem('read-it')}>
            <Link href={`/${urlHelper.articlePath(slug)}`} target="_blank">
              Les hele saken på Faktisk.no ↗
            </Link>
          </div>
        )}

        {!page && !smallEmbed && (
          <Container size={containerSize} {...bem('by-line')}>
            {isEmbed && <strong {...bem('publisher')}>Faktisk.no: </strong>}

            {authors && (
              <span>
                Av{' '}
                {authors.map(({ _id, name, email }, i) => (
                  <span key={_id}>
                    {email ? <a href={`mailto:${email}`}>{name}</a> : name}
                    {authors.length > 1 && i + 2 < authors.length ? ', ' : ''}
                    {authors.length > 1 && i + 2 === authors.length ? ' og ' : ''}
                  </span>
                ))}
              </span>
            )}

            {publishedAt && _updatedAt && (
              <span>
                {authors && '. '}
                {hasBeenUpdated ? (
                  <>
                    Publisert: {formatDate(publishedAt)}. Oppdatert:{' '}
                    {formatDate(_updatedAt, 'dd.MM.yyyy HH:mm')}
                  </>
                ) : (
                  <>Publisert: {formatDate(publishedAt, 'dd.MM.yyyy HH:mm')}</>
                )}
              </span>
            )}
            {'. '}
            {publishedAt && <Disclaimer publishedAt={publishedAt} tags={tags} />}
          </Container>
        )}

        <Container size={containerSize}>
          <RichText blocks={content} serializers={serializers} {...bem('content')} />
        </Container>

        {children && <Container size={containerSize}>{children}</Container>}

        <ArticleFooter
          content={content}
          tags={tags}
          slug={slug}
          corrections={corrections}
          related={related}
        />
      </article>
    </ReferencesOpenContext.Provider>
  )
}

export interface HeaderProps {
  borderless?: boolean
  page?: boolean
  slug?: { current: string; pretty: string }
  title?: string
  genre?: string
  preamble?: string
  authors?: Array<{
    name: string
    _id: string
  }>
  _createdAt?: string
  _updatedAt?: string
}

export const ArticleHeader: React.FC<HeaderProps> = ({
  borderless,
  slug,
  title,
  genre,
  page,
  preamble, // For other pages, not articles
}) => {
  const isEmbed = useIsEmbedded()
  return (
    <Container {...bem('header', { borderless: borderless || page, embed: isEmbed })}>
      {title ? <h1 {...bem('title')}>{breakLongWord(title)}</h1> : null}
      {preamble && <p {...bem('preamble', 'header')}>{preamble}</p>}

      {genre && !page && (
        <div {...bem('meta')}>
          {genre && <strong {...bem('genre')}>{genre}</strong>}

          {slug && !isEmbed && (
            <Link target="_top" href={`/${urlHelper.articlePath(slug)}?embed=1`} rel="nofollow">
              Embed artikkel
            </Link>
          )}
        </div>
      )}
    </Container>
  )
}

interface FooterProps {
  slug?: { current: string; pretty: string }
  tags?: TagType[]
  corrections?: Corrections[]
  related?: Related[]
  content: Blocks
}

const ArticleFooter: React.FC<FooterProps> = ({ tags, slug, corrections, related, content }) => {
  const isEmbed = useIsEmbedded()
  const { open: referencesOpen } = useContext(ReferencesOpenContext)
  const filteredTags = tags?.filter(({ name }) => name !== 'Facebook 3PFC')
  const facebookCollaboration = tags?.some(({ name }) => name === 'Facebook 3PFC')
  const hasCorrections = corrections && corrections.length > 0

  const sources: MarkDef[] = content
    .filter((block) => block?.markDefs?.length)
    .reduce(
      (marks, { markDefs }) => [...marks, ...filter(markDefs, { _type: 'source' })],
      [] as MarkDef[],
    )

  if (!tags && !corrections && sources.length === 0) {
    return null
  }

  return (
    <>
      <Container node="footer" {...bem('footer', { embed: isEmbed })}>
        {slug && !isEmbed && (
          <Button
            primary
            label="Embed artikkel"
            target="_top"
            href={`/${urlHelper.articlePath(slug)}?embed=1`}
            rel="nofollow"
            {...bem('embed-button')}
          />
        )}

        {facebookCollaboration && (
          <p {...bem('facebook')}>
            Denne artikkelen er del av{' '}
            <a href="https://www.faktisk.no/facebook" target="_blank" rel="noopener noreferrer">
              vårt samarbeid med Facebook
            </a>
            .
          </p>
        )}

        {hasCorrections && (
          <Accordion title="Endringslogg">
            <ul>
              {corrections?.map(({ _key, timestamp, body }) => (
                <li key={_key} {...bem('correction')}>
                  <time>{formatDate(timestamp)}</time>
                  <p>{portableToPlain(body)}</p>
                </li>
              ))}
            </ul>
            <Link {...bem('corrections-link')} href="/endringer">
              Se alle artikkelendringer på Faktisk.no
            </Link>
          </Accordion>
        )}

        {sources?.length ? <Sources sources={sources} expanded={referencesOpen} /> : null}

        {tags && !isEmbed && filteredTags?.length ? <Tags items={filteredTags} /> : null}

        {related?.length && !isEmbed ? (
          <ArticleEntries title="Les også" articles={related} />
        ) : null}
      </Container>
    </>
  )
}

export default Article
