import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import Tag from '../../types/tag'

const bem = BEMHelper(styles)

export interface TagType extends Tag {
  href?: string
}

export interface Row {
  _key: string
  cells: string[]
}

interface Props {
  title?: string
  caption?: string
  source?: string
  sourceUrl?: string
  width?: string
  table: {
    rows: Row[]
  }
}

const Table: React.FC<Props> = ({ title, caption, source, sourceUrl, table, width }) => {
  if (!table || !table.rows || !table.rows.length) {
    return null
  }

  const headers = table.rows[0].cells
  const items = table.rows.filter((_, index) => index > 0)

  return (
    <section {...bem('', width)}>
      <div {...bem('container')}>
        <div {...bem('content')}>
          {title ? <h2 {...bem('title')}>{title}</h2> : null}

          {(caption || source) && (
            <p {...bem('caption')}>
              {caption && `${caption} `}
              {source && !sourceUrl && <span>Kilde: {source}</span>}
              {source && sourceUrl && (
                <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
                  Kilde: {source}
                </a>
              )}
            </p>
          )}

          <table {...bem('table')}>
            <thead>
              <tr {...bem('header')}>
                {headers.map((text, index) => (
                  <th scope="col" {...bem('cell', 'header')} key={index}>
                    {text}
                  </th>
                ))}
              </tr>
            </thead>

            <tbody>
              {items.map(({ cells, _key }) => (
                <tr key={_key} {...bem('row')}>
                  {cells.map((text, index) => (
                    <td {...bem('cell')} key={_key + index}>
                      {text}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </section>
  )
}

export default Table
