import React, { useRef, useEffect } from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)

// Script elements inserted using innerHTML do not execute when they are inserted, according to the HTML spec (https://www.w3.org/TR/2008/WD-html5-20080610/dom.html#innerhtml0)
// This component makes sure we can insert HTML snippets that contain <script> elements.
interface Props {
  html: string
  className?: string
  clean?: boolean
  style?: string
  caption?: string
  source?: string
  sourceUrl?: string
}

const CodeFragment: React.FC<Props> = ({
  html,
  clean,
  className,
  caption,
  style,
  source,
  sourceUrl,
}) => {
  const ref = useRef<HTMLDivElement>(null)

  useEffect(() => {
    const currentRef = ref.current
    if (currentRef) {
      const range = document.createRange()
      range.selectNode(currentRef)
      const fragment = range.createContextualFragment(html)
      currentRef.appendChild(fragment)
      range.detach()

      return () => {
        if (currentRef) {
          Array.from(currentRef.children).forEach((c) => {
            if (currentRef) {
              currentRef.removeChild(c)
            }
          })
        }
      }
    }
  }, [html])

  if (clean) {
    return <div ref={ref} {...bem('', { clean }, className)} />
  }

  const Wrapper = caption ? 'figure' : 'div'

  return (
    <Wrapper {...bem('', style, className)}>
      <div {...bem('container')}>
        <div {...bem('content')}>
          <div ref={ref} {...bem('embed')} />
          {(caption || source) && (
            <figcaption {...bem('caption')}>
              {caption && `${caption} `}
              {source && !sourceUrl && <span>Kilde: {source}</span>}
              {source && sourceUrl && (
                <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
                  Kilde: {source}
                </a>
              )}
            </figcaption>
          )}
        </div>
      </div>
    </Wrapper>
  )
}

export default CodeFragment
