import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import { formatDate } from '../../helpers/date'

import Accordion from '../Accordion'

const bem = BEMHelper(styles)

interface Props {
  sources: Array<{
    _key: string
    _type: string
    href: string
    name: string
    description: string
    date: string
  }>
  expanded?: boolean
}

const Sources: React.FC<Props> = ({ sources = [], expanded }) => {
  if (!sources || !sources.length) {
    return null
  }

  return (
    <Accordion title="Referanser" expanded={expanded}>
      <ul {...bem('')}>
        {sources.map((source) => {
          if (source._type !== 'source') {
            return null
          }

          return (
            <li key={source._key} id={`ref-${source._key}`} {...bem('item')}>
              <a href={source.href} target="_blank" rel="nofollow noreferrer" {...bem('link')}>
                <h3 {...bem('title')}>{source.name}</h3>
                <p {...bem('description')}>{source.description}</p>
                <time {...bem('date')}>{formatDate(source.date)}</time>
              </a>
            </li>
          )
        })}
      </ul>
    </Accordion>
  )
}

export default Sources
