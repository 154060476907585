import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import Container from '../Container'
import { getImageUrl } from '../../api/sanity'
import ImageFields from '../../types/image'
import Image from 'next/image'

const bem = BEMHelper(styles)

export interface Person {
  _id: string
  name: string
  email: string
  role: string
  details?: string
  image?: ImageFields
}

interface Props {
  list: Person[]
  title?: string
}

const People: React.FC<Props> = ({ title, list }) => {
  if (!list) {
    return null
  }

  const SubTitle = title ? 'h3' : 'h2'

  return (
    <Container node="section" breakOut {...bem('')}>
      {title && <h2 {...bem('title')}>{title}</h2>}

      <ul {...bem('list')}>
        {list.map(({ _id, name, email, role, details, image }) => (
          <li {...bem('person')} key={_id}>
            <figure {...bem('avatar')}>
              <svg {...bem('aspect')} viewBox="0 0 3 4" />
              {image && (
                <Image
                  src={getImageUrl(image).width(396).height(500).url() || ''}
                  alt={`Portrett av ${name}`}
                  {...bem('image')}
                  height={500}
                  width={396}
                />
              )}
            </figure>

            <div>
              <SubTitle {...bem('name')}>{name}</SubTitle>
              <span {...bem('meta')}>{role}</span>
              <a href={`mailto:${email}`} {...bem('meta')}>
                {email}
              </a>
              <p {...bem('description')}>{details}</p>
            </div>
          </li>
        ))}
      </ul>
    </Container>
  )
}

export default People
