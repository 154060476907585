/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
import React, { useState, useRef } from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import { getNumber, formatBigNumber } from '../../helpers/utils'
import breakLongWord from '../../helpers/breakLongWord'
import useResize from '../../hooks/useResize'
import Tag from '../../types/tag'
import { COLORS } from '../../constants'

const bem = BEMHelper(styles)

export interface TagType extends Tag {
  href?: string
}

export interface Row {
  _key: string
  cells: string[]
}

interface Props {
  title?: string
  caption?: string
  source?: string
  sourceUrl?: string
  style?: string
  width?: string
  table: {
    rows: Row[]
  }
}

function getMaxValue(items: Row[]): number {
  const values = items.map(({ cells }) =>
    cells
      .filter((_, index) => index > 0)
      .reduce((result, item) => getNumber(result) + getNumber(item), 0),
  )
  const max = values.reduce<number>((max, item) => (max < item ? item : max), 0)

  const devider = Number(
    1 +
      Array(Math.floor(max.toString().length / 1.5))
        .fill(0)
        .join(''),
  )

  const maxWithPadding = Math.ceil(Math.ceil((max * 1.1) / devider)) * devider

  return maxWithPadding
}

const makeSpots = (max: number): number[] => {
  const pieces = max / 5

  return [0, pieces, pieces * 2, pieces * 3, pieces * 4, max].reverse()
}

const BarChart: React.FC<Props> = ({ title, caption, source, sourceUrl, table, width, style }) => {
  const [nameHeight, setNameHeight] = useState<number | null>(null)
  const [chartWidth, setChartWidth] = useState<number | null>(null)
  const colorsRef = useRef<HTMLDivElement>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const [selectedColor, setSelectedColor] = useState<number>(-1)
  const [barHover, setBarHover] = useState<string | null>(null)

  useResize(() => {
    if (contentRef.current) {
      setChartWidth(null)
      setTimeout(() => setChartWidth(contentRef.current?.scrollWidth || null), 10)
    }

    if (colorsRef.current) {
      const height = colorsRef.current.scrollHeight

      if (height) {
        setNameHeight(height + 16)
      }
    }
  }, [contentRef.current, colorsRef.current])


  if (!table || !table.rows || !table.rows.length) {
    return null
  }

  const handleSelectColor = (value: number) => () => {
    setSelectedColor((current) => (current === value ? -1 : value))
  }

  const handleBarHover = (value: string | null) => () => {
    setBarHover(value || null)
  }

  const headers = table.rows[0].cells.filter((_, index) => index > 0)
  const single = headers.length === 1
  const items = table.rows.filter((_, index) => index > 0)
  const max = getMaxValue(items)
  const spots = makeSpots(max)
  const crowded = items.length > 10

  const percentage = (value: string | number): number => {
    return (getNumber(value) / max) * 100
  }

  return (
    <section
      {...bem('', { single, [width || '']: width, crowded, [style || '']: style })}
      style={chartWidth ? ({ '--name-size': nameHeight + 'px' } as React.CSSProperties) : {}}
    >
      <div {...bem('container')}>
        <div {...bem('content')}>
          {title ? <h2 {...bem('title')}>{title}</h2> : null}

          {(caption || source) && (
            <p {...bem('caption')}>
              {caption && `${caption} `}
              {source && !sourceUrl && <span>Kilde: {source}</span>}
              {source && sourceUrl && (
                <a href={sourceUrl} target="_blank" rel="noopener noreferrer">
                  Kilde: {source}
                </a>
              )}
            </p>
          )}

          <div
            {...bem('chart', { hover: !!barHover, selection: selectedColor > -1 })}
            ref={contentRef}
            style={chartWidth ? ({ '--width': chartWidth + 'px' } as React.CSSProperties) : {}}
          >
            <ol {...bem('spots')}>
              {spots.map((value) => (
                <li key={value} {...bem('spot')}>
                  <span {...bem('spot-value')}>{formatBigNumber(value)}</span>
                </li>
              ))}
            </ol>

            <ul {...bem('values')}>
              {items.map(({ cells, _key }) => {
                const name = cells[0]
                const values = cells.filter((_, index) => index > 0)

                return (
                  <li key={_key} {...bem('group')} tabIndex={0}>
                    <h3 {...bem('name')}>{breakLongWord(name, 10)}</h3>

                    <div {...bem('bars')}>
                      {values.map((value, index) => {
                        const key = _key + index
                        const active = barHover === key || selectedColor === index

                        return (
                          <div
                            {...bem('bar', { active })}
                            style={
                              {
                                '--percentage': `${percentage(value)}%`,
                                '--color': COLORS[index],
                              } as React.CSSProperties
                            }
                            key={key}
                            aria-label={`${headers[index]}: ${value}`}
                            onMouseEnter={handleBarHover(key)}
                            onMouseLeave={handleBarHover(null)}
                          >
                            <span {...bem('tooltip')}>
                              {headers[index]}:<br />
                              {value}
                            </span>
                          </div>
                        )
                      })}
                    </div>
                  </li>
                )
              })}
            </ul>
          </div>

          {!single && (
            <nav {...bem('colors')} ref={colorsRef}>
              {headers.map((name, index) => (
                <button
                  {...bem('color', { inactive: selectedColor !== index && selectedColor > -1 })}
                  key={`color-${index}`}
                  style={{ '--color': COLORS[index] } as React.CSSProperties}
                  onClick={handleSelectColor(index)}
                  data-name={name}
                >
                  <div {...bem('color-box')} />
                  {name}
                </button>
              ))}
            </nav>
          )}
        </div>
      </div>
    </section>
  )
}

export default BarChart
