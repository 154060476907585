import React, { useState } from 'react'
import ReactYouTube from 'react-youtube'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import Modal from '../Modal'
import Icon from '../Icon'
import { getImageUrl } from '../../api/sanity'
import ImageFields from '../../types/image'
import useIsEmbedded from '../../hooks/useIsEmbedded'

const bem = BEMHelper(styles)

export interface Props {
  id: string
  caption?: string
  image?: ImageFields
  main?: boolean
}

const YouTube: React.FC<Props> = ({ id, caption, image, main }) => {
  const isEmbed = useIsEmbedded()
  const [top, setTop] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const styles = { paddingBottom: '56.25%' }

  const toggle = (value: boolean) => (event?: { target? }) => {
    if (value) {
      setExpanded(true)

      if (event?.target) {
        setTop(Math.round(event?.target?.getBoundingClientRect().top))
      }
    } else {
      setExpanded(false)
    }
  }

  const cover = image
    ? getImageUrl(image).width(1000).url()
    : `https://img.youtube.com/vi/${id}/hqdefault.jpg`

  return (
    <>
      <figure {...bem('', { main })}>
        <button
          type="button"
          {...bem('trigger')}
          onClick={toggle(true)}
          style={{
            backgroundImage: `url(${cover})`,
          }}
        >
          <span {...bem('play')}>
            <Icon icon="play" {...bem('icon')} /> Spill av
          </span>
        </button>

        {caption && <figcaption {...bem('caption')}>{caption}</figcaption>}
      </figure>

      <Modal
        expanded={!!expanded}
        onClose={toggle(false)}
        full
        preventClickOutside={!isEmbed}
        top={isEmbed && top ? top : undefined}
      >
        <div {...bem('aspect')} style={styles}>
          <ReactYouTube
            {...bem('video')}
            videoId={id}
            opts={{
              playerVars: {
                rel: 0,
                modestbranding: 1,
                // playlist: 0,
                // origin: 1,
                autoplay: 1,
              },
            }}
          />
        </div>
      </Modal>
    </>
  )
}

export default YouTube
