import React from 'react'
import { get } from 'lodash'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

// Check the children to see if it contains long sentences or short
const hasLongListItem = (children: any) => {
  if (!children) {
    return false
  }
  const list = React.Children.toArray(children)
  if (!list || !list.length) {
    return false
  }

  const texts = list.map((child) => {
    const children = get(child, 'props.node.children', []) || []
    const childTexts = children.map((child: any) => get(child, 'text'))
    return Array.isArray(childTexts) ? childTexts.join('') : ''
  })

  return Array.isArray(texts) ? texts.some((str) => str.length > 40) : false
}

interface ListItemProps {
  children: JSX.Element[] | JSX.Element | string
  outdent?: boolean
}

const ListItem: React.FC<ListItemProps> = ({ children, outdent }) => {
  return <li {...bem('item', { outdent })}>{children}</li>
}

interface ListProps {
  children?: JSX.Element[] | JSX.Element | string
  className?: string
  type?: 'ul' | 'ol'
}

const List: React.FC<ListProps> = ({ children, className, type = 'ul' }) => {
  const Node = type as any
  const tight = !hasLongListItem(children)

  return (
    <Node {...bem('', { numbered: type === 'ol', normal: type !== 'ol', tight }, className)}>
      {children}
    </Node>
  )
}

export { List, ListItem }
