import React from 'react'

import Link from '../Link'
import Facts, { Box } from '../Facts'
import { formatDate } from '../../helpers/date'
import portableToPlain from '../../helpers/portableToPlain'
import { LEGACY_RATINGS } from '../../constants'
import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'

const bem = BEMHelper(styles)

type RATING_VALUE = '1' | '2' | '3' | '4' | '5'

interface Claimants {
  _id: string
  name: number | string | undefined
}

interface Props {
  quote?: string
  claimants?: Claimants[]
  source?: string
  sourceUrl?: string
  claimAt?: string
  conclusion?: any
  rating?: RATING_VALUE
}

const ClaimConclusion: React.FC<Props> = ({
  quote,
  claimants,
  source,
  sourceUrl,
  claimAt,
  conclusion,
  rating,
}) => {
  const conclusionText = portableToPlain(conclusion)
  const type = conclusionText.length > 600 ? 'small' : undefined

  return (
    <div {...bem('')}>
      <Facts>
        <Box
          title="Påstand"
          type={type}
          extra={
            <div>
              <>
                {(claimants || []).map(({ _id, name }, index) => (
                  <span key={_id}>
                    {name}
                    {index === (claimants || []).length - 1 ? '. ' : ', '}
                  </span>
                ))}
              </>
              {sourceUrl && !claimAt && source ? <Link href={sourceUrl}>{source}</Link> : source}
              {claimAt && (
                <>
                  {', '}
                  {sourceUrl ? (
                    <Link href={sourceUrl}>{formatDate(claimAt)}</Link>
                  ) : (
                    formatDate(claimAt)
                  )}
                </>
              )}
            </div>
          }
        >
          <p>{quote}</p>
        </Box>

        <Box
          title="Konklusjon"
          type={type}
          extra={
            rating &&
            LEGACY_RATINGS[rating] &&
            `Påstanden er ${LEGACY_RATINGS[rating] || 'ukjent'}.`
          }
        >
          <p>{portableToPlain(conclusion)}</p>
        </Box>
      </Facts>
    </div>
  )
}

export default ClaimConclusion
