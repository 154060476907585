/* eslint-disable @next/next/no-img-element */
import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'

const bem = BEMHelper(styles)

const IfcnBadge: React.FC = () => {
  return (
    <div {...bem('')}>
      <a
        href="https://ifcncodeofprinciples.poynter.org/profile/faktiskno"
        title="IFCN signatory"
        target="_blank"
        rel="nofollow noreferrer"
      >
        <img
          src="https://cdn.ifcncodeofprinciples.poynter.org/storage/badges/681E6E3C-1A03-F91E-C643-9FB26E8BCAFA.png"
          {...bem('image')}
          alt="International Fact-Checking Network-bekreftelse"
        />
      </a>
    </div>
  )
}

export default IfcnBadge
