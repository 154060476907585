import React from 'react'

import { get } from 'lodash'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import { formatDateRelative } from '../../helpers/date'
import breakLongWord from '../../helpers/breakLongWord'
import Button from '../Button'
import Link from '../Link'
import { getImageUrl } from '../../api/sanity'
import { ArticleSearchHit } from '../../hooks/useSearch'
import ImageFields from '../../types/image'
import Image from 'next/image'

const bem = BEMHelper(styles)

export interface Article {
  preamble?: any
  shareDescription?: string
  _id?: string
  objectID?: string
  slug?: {
    current: string
    pretty: string
  }
  title: string
  publishedAt?: string
  mainImage: ImageFields | string
  color?: string
  genre: string
}

interface Props {
  articles: Array<Article | ArticleSearchHit>
  className?: string
  title?: string
  promoted?: boolean
  loadMore?: {
    onClick: () => void
    label: string
    loading?: boolean
  }
}

const getLink = (article: Article): string => {
  if (article.slug?.current) {
    return `/artikler/${article.slug?.current}/${article.slug?.pretty}`
  }

  return `/artikler/${article.slug}`
}

const makeImageAspectStyles = (article: {
  color?: Article['color']
  mainImage: any
}): React.CSSProperties => {
  const color = article.color || article.mainImage?.backgroundColor || ''
  const facsimile = get(article, 'mainImage.facsimile.isFacsimile', false)
  return facsimile ? {} : { backgroundColor: color }
}

const SIZES = [
  [200, 200],
  [600, 400],
]

const ArticleEntries: React.FC<Props> = ({ title, articles, className, promoted, loadMore }) => {
  if (!articles) {
    return null
  }

  const ArticleTitle = title ? 'h3' : 'h2'
  const list = articles?.filter(({ _id, objectID }) => _id || objectID)

  return (
    <section {...bem('', { promoted }, className)}>
      {title && <h2 {...bem('main-title')}>{title}</h2>}

      <nav {...bem('list', { double: list.length === 4 })}>
        {list?.map((article) => {
          const hasImage = article.mainImage
          const imageSrc = typeof article.mainImage === 'string' ? article.mainImage : null
          const alt = typeof article.mainImage !== 'string' ? article.mainImage?.altText || '' : ''

          const facsimile = get(article, 'mainImage.facsimile.isFacsimile', false)
          const tag = (facsimile && get(article, 'mainImage.facsimile.tag', null)) || null
          let imageUrls

          if (hasImage) {
            imageUrls = !imageSrc
              ? {
                  large: facsimile
                    ? getImageUrl(article.mainImage).width(SIZES[1][0]).url() || ''
                    : getImageUrl(article.mainImage).width(SIZES[1][0]).height(SIZES[1][1]).url() ||
                      '',
                  small: facsimile
                    ? getImageUrl(article.mainImage).width(SIZES[0][0]).url() || ''
                    : getImageUrl(article.mainImage).width(SIZES[0][0]).height(SIZES[0][1]).url() ||
                      '',
                }
              : {}
          }

          return (
            <Link key={article._id || article.objectID} href={getLink(article)} {...bem('item')}>
              {hasImage ? (
                <div {...bem('image-aspect', { facsimile })} style={makeImageAspectStyles(article)}>
                  {tag && <strong {...bem('tag')}>{tag}</strong>}
                  {imageSrc ? (
                    <Image src={imageSrc} alt="" {...bem('image')} fill />
                  ) : (
                    <picture {...bem('image')}>
                      <source media="(min-width: 760px)" srcSet={imageUrls.large} />
                      <Image src={imageUrls.small} alt={alt} fill />
                    </picture>
                  )}
                </div>
              ) : (
                <div {...bem('image-aspect', 'fallback')}>
                  <span {...bem('image-fallback')}>{article.genre}</span>
                </div>
              )}

              <div>
                <span {...bem('meta')}>
                  <strong {...bem('genre')}>{article.genre}</strong>
                  {article.publishedAt && (
                    <time {...bem('date')}>{formatDateRelative(article.publishedAt)}</time>
                  )}
                </span>
                <ArticleTitle {...bem('title')}>{breakLongWord(article.title)}</ArticleTitle>
              </div>
            </Link>
          )
        })}
      </nav>
      {loadMore && (
        <div {...bem('more')}>
          <Button center label={loadMore.label} onClick={loadMore.onClick} />
        </div>
      )}
    </section>
  )
}

export default ArticleEntries
