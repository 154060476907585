import {
  differenceInCalendarDays,
  format,
  formatDistance as originalFormatDistance,
  parseISO,
} from 'date-fns'
import {nb} from 'date-fns/locale'

export function formatDate(date?: string, dateFormat = 'dd.MM.yyyy', fallback = '—'): string {
  if (!date) {
    return fallback
  }

  return format(parseISO(date), dateFormat, {locale: nb })
}

export function formatDistance(date: Date, baseDate: Date = new Date()): string {
  return originalFormatDistance(date, baseDate, {locale: nb, addSuffix: true })
}

export function formatDateRelative(
  date: string,
  baseDate = new Date(),
  maxDistanceDays = 7,
): string {
  const d = parseISO(date)
  const distance = differenceInCalendarDays(baseDate, d)

  if (distance < maxDistanceDays) {
    return originalFormatDistance(d, baseDate, { locale:nb, addSuffix: true })
  } else {
    return formatDate(date)
  }
}
