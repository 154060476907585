import { SearchResponse } from '@algolia/client-search'
import { RequestOptions } from '@algolia/transporter'
import { useState, useEffect } from 'react'
import { articles, articles_chronological } from '../api/algolia'
import { debounce } from 'lodash'
import { AlgoliaSearchOptions } from 'algoliasearch'

type SearchOptions = RequestOptions & AlgoliaSearchOptions

export interface ArticleSearchHit {
  _id: undefined
  objectID: string
  shareDescription: string
  slug: { current: string; pretty: string }
  title: string
  publishedAt: string
  mainImage: string
  color: string
  genre: string
}

const doSearch = debounce(
  (query: string, options: SearchOptions) => articles.search<ArticleSearchHit>(query, options),
  300,
  { leading: true }, // not ideal, but p-debounce makes the build more complex
) as unknown as typeof articles['search']

const doChronologicalSearch = debounce(
  (query: string, options: SearchOptions) =>
    articles_chronological.search<ArticleSearchHit>(query, options),
  300,
  { leading: true }, // not ideal, but p-debounce makes the build more complex
) as unknown as typeof articles['search']

export default function useSearch(
  query: string,
  ranking: 'relevance' | 'publishedAt',
  options?: SearchOptions,
  hitsPerPage = 52,
): {
  hits: ArticleSearchHit[]
  error: Error | null
  loading: boolean
  hasSearched: boolean
} {
  const [hasSearched, setHasSearched] = useState(false)
  const [loading, setLoading] = useState(false)
  const [hits, setHits] = useState<ArticleSearchHit[]>([])
  const [error, setError] = useState<Error | null>(null)

  /**
   * since options is an object we need to serialize it to a string to
   * prevent entering an infinite render loop
   */
  const serializedOptions = JSON.stringify(options)
  const searchFunc = ranking === 'publishedAt' ? doChronologicalSearch : doSearch

  useEffect(() => {
    async function search() {
      if (!query || query.length < 2) {
        setLoading(false)
        setError(null)
        setHits([])
        return
      }

      try {
        setLoading(true)
        setHasSearched(true)

        const { hits } = (await searchFunc(query, {
          hitsPerPage,
          attributesToRetrieve: [
            'objectID',
            'shareDescription',
            'slug',
            'title',
            'publishedAt',
            'mainImage',
            'color',
            'genre',
          ],
          ...(options ? options : {}),
        })) as unknown as Readonly<SearchResponse<ArticleSearchHit>>

        setError(null)
        setHits(hits)
      } catch (e) {
        setError(e)
      } finally {
        setLoading(false)
      }
    }

    search()
  }, [query, serializedOptions, hitsPerPage])

  return { loading, error, hits, hasSearched }
}
