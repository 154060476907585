import { hyphenateSync } from 'hyphen/no'

export default function breakLongWord(string: string, minLength = 12): string {
  if (string) {
    const hyphenated = hyphenateSync(string, {
      minWordLength: minLength,
    })

    if (string.length !== hyphenated.length) {
      // Cut the hyphenation of the first part and the last part of the string,
      // if it is broken into more than three pieces.
      const fixedEdges = hyphenated
        .split(' ')
        .map((string: string) => {
          const hyphen = string.split('\u00AD')

          if (hyphen.length > 3) {
            if (hyphen[0].length <= 4) {
              hyphen[0] += hyphen[1]
              hyphen.splice(1, 1)
            }
            if (hyphen[hyphen.length - 1].length <= 4) {
              hyphen[hyphen.length - 2] += hyphen[hyphen.length - 1]
              hyphen.length = hyphen.length - 1
            }
            return hyphen.join('\u00AD')
          }

          return string
        })
        .join(' ')

      return fixedEdges
    }
  }

  return string
}

export function breakEmail(string: string): string {
  return string.split('@').join('\u00AD@')
}
