import React from 'react'
import { find } from 'lodash'
import { addSeconds, differenceInYears } from 'date-fns'
import { formatDistance } from '../../helpers/date'

import { Article } from '../../types/article'
import Tag from '../../types/tag'

interface Props {
  publishedAt?: Article['publishedAt']
  tags?: Tag[]
  className?: string
}

const customTagDisclaimer = [
  {
    id: 'imported-event-3877',
    threshold: 2 * 24 * 60 * 60,
    message(date) {
      return (
        <span>
          Denne artikkelen ble publisert for <strong>{formatDistance(date, new Date())}</strong> og
          kan inneholde utdaterte opplysninger eller råd om koronaviruset.
        </span>
      )
    },
  },
]

const Disclaimer: React.FC<Props> = ({ publishedAt, tags, className }) => {
  if (!publishedAt) {
    return null
  }

  const publishedDate = new Date(publishedAt)

  const customDisclaimer = customTagDisclaimer.find(({ id, threshold }) => {
    if (!find(tags, { _id: id })) {
      return false
    }

    if (addSeconds(publishedDate, threshold) > new Date()) {
      return false
    }

    return true
  })

  if (customDisclaimer) {
    return customDisclaimer.message(publishedDate)
  }

  if (differenceInYears(new Date(), publishedDate) >= 1) {
    return (
      <span className={className}>
        Denne artikkelen ble publisert for{' '}
        <strong>{formatDistance(publishedDate, new Date())}</strong> og kan inneholde utdatert
        informasjon.
      </span>
    )
  }

  return null
}

export default Disclaimer
