import React, { useRef } from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import urlHelper from '../../helpers/urls'
import useAccordion from '../../hooks/useAccordion'
import Tag from '../../types/tag'

import Icon from '../Icon'
import Link from '../Link'

const bem = BEMHelper(styles)

export interface TagType extends Tag {
  href?: string
}

interface Props {
  items: TagType[]
  title?: string
  list?: boolean
}

const Tags: React.FC<Props> = ({ items, list, title }) => {
  const wrapperRef = useRef<HTMLDivElement>(null)
  const { height, expanded, toggle } = useAccordion(wrapperRef)
  if (!items) {
    return null
  }

  if (list) {

    const styles = {
      '--height': height,
    } as React.CSSProperties

    return (
      <nav {...bem('', 'list')}>
        {title && (
          <button type="button" onClick={toggle} {...bem('toggle', { expanded })}>
            <h2 {...bem('title')}>{title}</h2>
            <span {...bem('indicator')}>
              <Icon icon="chevron" direction="down" />
            </span>
          </button>
        )}

        <div {...bem('wrapper', { expanded })} ref={wrapperRef} style={styles}>
          <div {...bem('list')}>
            {items.map(({ _id, _type, name, slug, href }) => (
              <Link href={href || urlHelper.tema(slug, _type)} {...bem('item')} key={_id}>
                <span {...bem('name')}>{name}</span>
              </Link>
            ))}
          </div>
        </div>
      </nav>
    )
  }

  return (
    <nav {...bem('')}>
      {title && <h2 {...bem('title')}>{title}</h2>}

      {items.map(({ _id, _type, name, slug, href }) => (
        <Link href={href || urlHelper.tema(slug, _type)} {...bem('item')} key={_id}>
          <span {...bem('name')}>{name}</span>
        </Link>
      ))}
    </nav>
  )
}

export default Tags
