import { debounce } from 'lodash'
import { useEffect } from 'react'

export default function useResize(func: () => void, listeners: Array<any>): void {
  useEffect(() => {
    const debounceFunc = debounce(func, 300)
    setTimeout(func, 10)

    window.addEventListener('resize', debounceFunc)

    return () => {
      window.removeEventListener('resize', debounceFunc)
    }
  }, listeners || [])
}
