import React from 'react'

import styles from './Styles.module.scss'
import BEMHelper from '../../helpers/bem'
import { formatDate } from '../../helpers/date'
import Link from '../Link'
import { get } from 'lodash'
import useIsEmbedded from '../../hooks/useIsEmbedded'

const bem = BEMHelper(styles)

interface Slug {
  current: string
  pretty: string
}

interface Props {
  reference?: {
    slug: Slug
    genre?: string
    title: string
    publishedAt: string
  }
  related?: Array<
    | {
        _type: 'external'
        _key: string
        title: string
        url: string
      }
    | {
        _id: string
        _type: 'article'
        slug: Slug
        title: string
        genre?: string
        publishedAt: string
      }
  >
}

const getURL = (_type: string, slug: Slug): string => {
  if (_type === 'article') {
    return `/artikler/${slug.current}/${slug.pretty}`
  }

  return `/${slug?.current}`
}

const ArticleTeaser: React.FC<Props> = ({ reference, related }) => {
  const isEmbed = useIsEmbedded()

  
  if (related && related.length > 0) {
    // we show the publised date only if the list conist of nothing but articles
    const showpublishedDate = !related.filter(Boolean).some((doc) => doc._type !== 'article')

    return (
      <nav>
        {related.filter(Boolean).map((entry) => {
          const { title } = entry
          const href = entry._type === 'external' ? entry.url : getURL(entry._type, entry.slug)
          const key = entry._type === 'external' ? entry._key : entry._id

          return (
            <Link href={href ?? ''} {...bem('')} key={key}>
              <span {...bem('meta')}>
                <strong {...bem('genre')}>{get(entry, 'genre', 'Faktisk.no')}</strong>
                {showpublishedDate && entry._type == 'article' ? (
                  <span {...bem('when')}>{formatDate(entry.publishedAt)}</span>
                ) : null}
              </span>
              <h2 {...bem('title')}>{title}</h2>
            </Link>
          )
        })}
      </nav>
    )
  }

  if (!reference?.slug?.current) {
    return null
  }


  return (
    <Link
      target={isEmbed ? '_top' : undefined}
      href={`/artikler/${reference.slug.current}/${reference.slug.pretty}`}
      {...bem('')}
    >
      <span {...bem('meta')}>
        <strong {...bem('genre')}>{reference.genre || 'Faktisk'}</strong>
        <span {...bem('when')}>{formatDate(reference.publishedAt)}</span>
      </span>
      <h2 {...bem('title')}>{reference.title}</h2>
    </Link>
  )
}

export default ArticleTeaser
