import { pick } from 'lodash'
import algoliasearch from 'algoliasearch'
import { getImageUrl } from './sanity'

export const appId = process.env.ALGOLIA_APP_ID || 'GKBLUNW1WL'

// Don't worry. This is the search API key. Read-only
export const token = process.env.ALGOLIA_TOKEN || '1fffd3193f7ff2c16650e5e9ce5ccfd7'
export const client = algoliasearch(appId, token)

export const articles = client.initIndex('articles')
export const articles_chronological = client.initIndex('articles_chronological')

export const articleFields = `{
  ...,
  mainImage{
    ...,
    asset->
  },
  body[] {
    ...,
    claimants[]->{
      _id,
      name
    }
  },
  tags[]->{
    _id,
    name
  },
  authors[]->{
    _id,
    name
  }
}
`

function blocksToText(blocks) {
  return (
    (blocks || [])
      // loop through each block
      .map((block) => {
        if (block._type === 'claimConclusion') {
          return [
            block.quote,
            block.simplifiedQuote,
            blocksToText(block.conclusion),
            ...block.claimants.map((e) => e.name),
          ].join('\n')
        }

        // if it's not a text block with children,
        // return nothing
        if (block._type !== 'block' || !block.children) {
          return ''
        }
        // loop through the children spans, and join the
        // text strings
        return block.children.map((child) => child.text).join('')
      })
      // join the paragraphs leaving split by two linebreaks
      .join('\n\n')
  )
}

function getClaimants(blocks) {
  return blocks
    .filter((b) => b._type === 'claimConclusion')
    .map((e) => e.claimants)
    .flat()
}

export function transformArticleToIndexable(doc) {
  return {
    objectID: doc._id,
    type: doc._type,
    publishedAt: doc.publishedAt,
    slug: pick(doc.slug, ['current', 'pretty']),
    title: doc.title,
    text: [blocksToText(doc.preamble), blocksToText(doc.body)].join('\n\n'),
    mainImage: doc?.mainImage?.asset
      ? getImageUrl(doc.mainImage).width(600).height(400).url()
      : null,
    color: doc?.mainImage?.asset?.metadata?.palette?.dominant?.background,
    shareDescription: doc.shareDescription,
    tags: doc.tags.map((e) => e.name),
    claimants: getClaimants(doc.body).map((e) => e.name),
    genre: doc.genre,
  }
}
