import React, { useState } from 'react'

import BEMHelper from '../../helpers/bem'
import styles from './Styles.module.scss'
import Modal from '../Modal'
import { getImageUrl } from '../../api/sanity'
import { IMAGE_SIZES } from '../../constants'
import useIsEmbedded from '../../hooks/useIsEmbedded'
import { default as NextImage } from 'next/image'

const PNG_TYPE = 'image/png'
const MAX_PNG_BYTES = 5e6 // 5 MB

const bem = BEMHelper(styles)

export interface ImageProps {
  altText?: string
  caption?: string
  credit?: string
  dimensions?: { width: number; height: number; aspectRatio: number }
  crop?: { left: number; right: number; top: number; bottom: number }
  width?: number
  height?: number
  size?: number
  type?: string
  style?: string
  mimeType?: string
  backgroundColor?: string
  className?: string
}

const NON_ENLARGABLE_TYPES = ['full', 'medium', 'default', 'normal']

const ENLARGED_MAX_WIDTHS = {
  default: 1100,
  xwide: 1500,
  wide: 1340,
  square: 900,
  portrait: 720,
  xportrait: 620,
}

const Image: React.FC<ImageProps> = ({
  altText,
  caption,
  credit,
  dimensions,
  className,
  style,
  backgroundColor,
  crop,
  ...props
}) => {
  const isEmbed = useIsEmbedded()
  const [top, setTop] = useState(0)
  const [expanded, setExpanded] = useState(false)
  const type = style || 'normal'
  const width = IMAGE_SIZES[type] || IMAGE_SIZES.default
  // const aspect = dimensions?.aspectRatio || 1
  const croppedWidth = dimensions
    ? dimensions?.width * (1 - (crop?.left || 0) - (crop?.right || 0))
    : 1000
  const croppedHeight = dimensions
    ? dimensions?.height * (1 - (crop?.top || 0) - (crop?.bottom || 0))
    : 1000
  const aspect = croppedWidth / croppedHeight || dimensions?.aspectRatio || 1

  const isPortrait = croppedWidth < croppedHeight
  const wideScreen = aspect > 2

  const toggle = (value: boolean) => (event?: { target? }) => {
    if (value) {
      setExpanded(true)

      if (event?.target) {
        setTop(Math.round(event?.target?.getBoundingClientRect().top))
      }
    } else {
      setExpanded(false)
    }
  }

  const image = getImageUrl(props)
  const format =
    props.mimeType === PNG_TYPE && props.size && props.size < MAX_PNG_BYTES ? 'png' : 'jpg'

  const src = image.width(width[0]).format(format).url()
  const srcLarge = image.width(width[1]).format(format).url()

  const styles = {
    '--aspect': aspect,
    '--background': backgroundColor || '',
  } as React.CSSProperties

  const enlargable = !isEmbed && NON_ENLARGABLE_TYPES.indexOf(type) === -1
  const ImageWrapper = enlargable ? 'button' : 'div'
  const enlargedWidth =
    (aspect < 0.7 && ENLARGED_MAX_WIDTHS.xportrait) ||
    (isPortrait && ENLARGED_MAX_WIDTHS.portrait) ||
    (wideScreen && ENLARGED_MAX_WIDTHS.xwide) ||
    (aspect > 1.5 && ENLARGED_MAX_WIDTHS.wide) ||
    (aspect > 0.9 && aspect < 1.2 && ENLARGED_MAX_WIDTHS.square) ||
    ENLARGED_MAX_WIDTHS.default
  const enlargedSrc = image
    .width(enlargedWidth + 100)
    .format(format)
    .url()

  return (
    <>
      <figure {...bem('', type, className)}>
        <div {...bem('container')}>
          <div {...bem('content')}>
            <ImageWrapper
              {...bem('aspect', { portrait: isPortrait, wide: wideScreen, enlargable })}
              style={styles}
              onClick={enlargable ? toggle(true) : undefined}
            >
              <picture>
                <source media="(min-width: 760px)" srcSet={srcLarge || ''} />
                <NextImage
                  {...bem('image', { portrait: isPortrait, wide: wideScreen })}
                  src={src || ''}
                  alt={altText || ''}
                  width={dimensions?.width}
                  height={dimensions?.height}
                />
              </picture>
            </ImageWrapper>

            {(caption || credit) && (
              <figcaption {...bem('caption')}>
                {caption && `${caption} `}
                {credit && <span {...bem('credit')}>{credit}</span>}
              </figcaption>
            )}
          </div>
        </div>
      </figure>

      {enlargable && (
        <Modal
          expanded={!!expanded}
          onClose={toggle(false)}
          full
          width={enlargedWidth}
          top={isEmbed && top ? top : undefined}
        >
          <div {...bem('aspect')} style={styles}>
            <NextImage {...bem('image')} src={enlargedSrc || ''} alt={altText || ''} />
          </div>
        </Modal>
      )}
    </>
  )
}

export default Image
